$primaryP1: rgba(80, 78, 74, 1);
$primaryP2: rgba(243, 118, 74, 1);
$secondaryS1: rgba(243, 118, 74, 0.6);
$secondaryS2: rgba(243, 118, 74, 1);
$secondaryS3: rgba(243, 118, 74, 1);
$secondaryS4: rgba(243, 118, 74, 1);
$greyG1: rgba(0, 0, 0, 1);
$greyG2: rgba(0, 0, 0, 1);
$greyG3: rgba(80, 78, 74, 1);
$greyG4: rgba(170, 170, 170, 1);
$greyG5: rgba(217, 217, 217, 1);
$greyG6: rgba(243, 243, 243, 1);
$greyG7: rgba(250, 250, 250, 1);
$greyG8: rgba(255, 255, 255, 1);
$opacityOP1: rgba(0, 0, 0, 0.5);
$opacityOP2: rgba(0, 0, 0, 0.95);


$primaryP2HueRotate: hue-rotate(45deg) ;

$primaryP2Filter: brightness(0) saturate(100%) invert(51%) sepia(61%) saturate(584%) hue-rotate(328deg) brightness(99%) contrast(92%);
$secondaryS1Filter: brightness(0) saturate(100%) invert(51%) sepia(61%) saturate(584%) hue-rotate(328deg) brightness(99%) contrast(92%) opacity(60%);
$greyG3Filter: brightness(0) saturate(100%) invert(30%) sepia(12%) saturate(83%) hue-rotate(327deg) brightness(93%) contrast(93%);
$greyG4Filter: brightness(0) saturate(100%) invert(70%) sepia(0%) saturate(517%) hue-rotate(132deg) brightness(97%) contrast(93%);
$greyG8Filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(273deg) brightness(102%) contrast(102%);

// page navigator style
$navigatorBackground:linear-gradient(30deg, $primaryP1, 0%, $primaryP2);
$navigatorTextColor: $greyG8;
$navigatorTextActiveColor: rgba($greyG8, 0.6);
$signupBtnBackground: rgba($primaryP2, 0.6);
$signupBtnColor: $navigatorTextColor;
$logoColor: $navigatorTextColor;

$enableCustomBtnStyle: 1;
$primaryButtonBackground: linear-gradient(30deg, $primaryP1, 0%, $primaryP2);
$primaryButtonColor: $greyG8;
$secondaryButtonBackground: $secondaryS2;
$secondaryButtonColor: $greyG8;
