@import "./Color.scss";

$inboxActiveBackground: #f4f4f4;

$greyishBrown: rgb(74, 74, 74);
$transparent: rgba(0, 0, 0, 0);
$black: rgb(43, 43, 43);
$veryLightPinkThree: rgb(209, 209, 209);
$veryLightPink: rgb(238, 238, 238);
$dustyOrange: rgb(250, 146, 48);
// font

$fontRegular: 'Wigrum-Light';      // 300
$fontMedium: 'Wigrum-Regular';        // 400
$fontSemiBold: 'Wigrum-Medium';    // 500
$fontBold: 'Wigrum-Bold';            // 600
$fontExtraBold: 'Wigrum-Black';  // 700
$fontHeavy: 'Wigrum-Black';          // 800

$containerMarginTop: 12px;
$containerMarginLeft: 15px;
$containerMarginRight: 15px;

$zIndexSlideMenu: 1000;
$zIndexAppNav: 999;
$zIndexFloatingTitle: 900;
