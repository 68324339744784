@import './src/frontend_common/Theme.scss';

.account-mobile-common-section {
  background-color: $greyG8;
  padding: 16px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 28px;

  > div {
    margin: 0;
  }

  .common-item-title {
    margin-bottom: 12px;
  }
  .common-item-value.field-text.gender > div {
    width: 100%;
  }
}

.custom-date-picker {
  display: inline;
}
