@import './src/frontend_common/Theme.scss';

div.common-shadow-section-container {
  border-radius: 8px;
  box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
  background-color: $greyG8;
  padding: 24px;
}

div.account-info-section {
  margin-top: 24px;
}

div.common-account-col {
  max-width: 375px;
}

span.personal_edit_button {
  float: right;
  color: $primaryP2;
  min-width: 58px;
  text-align: end;

  user-select: none;
  cursor: pointer;
  &.inactive {
    color: $secondaryS1;
  }
}

.common-section-title {
  text-align: left;
  color: $primaryP1;
  margin-bottom: 24px;
  width: 100%;
}

.common-account-item {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .common-item-value {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .update-button.button-large {
      &.account-input-save-button {
        margin-top: 8px;
        align-self: flex-end;
        height: 32px;
        width: 120px;
        padding: 0;
      }
    }
  }
  .add-another-btn {
    align-self: flex-start;
    margin-top: 8px;

    width: auto;
    height: 32px;
    padding: 0 16px;

    background-color: $greyG8;
    border: solid 2px $primaryP2;
    border-radius: 25px;

    color: $primaryP2;
    line-height: 30px;
    text-align: center;
    &:focus {
      outline: 0;
    }
  }
}

.contact-for-direct-marketing-title {
  margin-top: 28px;
}

.common-item-title {
  text-align: left;
  color: $greyG2;
  margin-bottom: 16px;
  word-break: break-all;
  display: flex;
  justify-content: space-between;
}

.common-item-value {
  text-align: left;
  color: $greyG3;
}

.account-common-prompt-information {
  color: $greyG4;
}

.check-message-detail {
  max-width: 280px;
  text-align: left;

  color: $greyG3;
}

.change-password-error {
  color: $secondaryS4 !important;
}

.custom-date-picker-div {
  position: relative;
  width: 342px;
  &:focus {
    outline: 0px;
  }
}

.account-input-with-downarrow {
  border: 0px;
  border-bottom: 1px solid $greyG5;
  width: 342px;

  text-align: left;

  background-color: $greyG8;

  color: $primaryP2;

  &::placeholder {
    color: $greyG4;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $greyG4;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $greyG4;
  }

  &:focus {
    outline: 0px;
  }
}

.account-input-with-downarrow-placeholder {
  color: $greyG4;
}

.account-down-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  filter: $primaryP2Filter;
}

.account-check-section {
  display: flex;

  .account-check-box {
    height: 24px;
    width: 24px;
    padding: 0px;
    background-color: $greyG8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    margin-right: 15px;
    // filter: $greyG3Filter;

    &:focus {
      outline: 0px;
    }
  }
}
.additional-field-input-save-button {
  margin-top: 8px;
  align-self: flex-end;
  height: 32px;
  width: 120px;
  padding: 0;
}
@media (max-width: 1023.98px) {
  div.common-shadow-section-container.account-change-password-section {
    border-radius: 0px;
    padding: 16px;
    margin-top: 8px;
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  div.account-mobile-common-section {
    margin-left: -15px;
    width: calc(100% + 30px);
    border-radius: 0px;
  }

  div.gender-picker-box {
    width: 100%;
  }
  .custom-date-picker-div {
    width: 100%;
  }

  .account-input-with-downarrow {
    width: 100%;
  }

  .common-section-title {
    margin-bottom: 16px;
  }
}
