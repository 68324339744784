@import "../../frontend_common/Theme.scss";

div.campagin_card {
  margin-top: 5%;
  width: 100%;
  border: none;
  cursor: default;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .campagin_card_image_area {
    position: relative;
    // width: 100%;
    // height: 0px;
    // padding-bottom: 66.6%;
    overflow: hidden;
    // box-shadow: 0px 0px 5px 0.5px rgba(0,0,0,0.1);

    .campaign_card_dimmed_image_area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $greyG8;
      border-radius: 8px;
      opacity: 0.6;
    }

    .campagin_card_mainImage_container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      .campagin_card_mainImage {
        height: 100%;
        width: 100%;
        object-fit: cover;
        // border-radius: 8px;
      }
    }

    .campagin_card_avatarImage_container {
      // position: absolute;
      // top: 5.5%;
      // right: 2.5%;
      // width: 13.17%;
      // height: 19.54%;

      .campagin_card_avatarImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
    }

    .campagin-card-tag {
      position: absolute;
      top: 15px;
      left: 12px;
      height: 26px;

      display: flex;
      align-items: center;
      justify-content: center;

      padding-left: 12px;
      padding-right: 12px;

      background-color: $primaryP1;

      text-align: center;

      color: $greyG8;
    }

    .campaign-tag-isOnlyForYou {
      background-color: $primaryP1;
    }

    .campaign-tag-isFeatured {
      background-color: $primaryP2;
    }

    .campagin_card_tag_leftDay {
      position: absolute;
      // left: 12px;
      // bottom: 0;
      // height: 20px;
      gap: 10px;

      display: inline-flex;
      padding: 2px 8px;
      align-items: center;
      justify-content: center;

      background-color: $greyG8;

      color: $greyG3;
    }

    .campaign_card_out_of_stock_label {
      margin: 0;
      padding: 0;
      color: $greyG8;
    }
  }

  .campagin_card_describe_area {
    margin-right: 5%;
    margin-top: 3%;

    text-align: start;
    color: $greyG3;

    display: block;
    display: -webkit-box;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .campagin_card_button_area {
    margin-top: 5%;
    position: relative;
    width: 100%;
    min-height: 60px;
    padding-bottom: 10%;

    .campagin_card_button_container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: end;
      flex-wrap: wrap;

      button.campagin_card_button {
        height: 100%;
        border-radius: 25px;
        border: none;
        gap: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        @if $enableCustomBtnStyle == 1 {
          background: $primaryButtonBackground;
          color: $primaryButtonColor;
          &:disabled {
            opacity: 0.6;
          }
        } @else {
          background: $primaryP2;
          &:disabled {
            background: $secondaryS1;
            border-color: $secondaryS1;
          }
        }
      }
    }

    .campaign_card_process_container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .ant-progress-inner {
        background-color: $greyG6;
      }
      .ant-progress-bg {
        background-color: $primaryP2;
      }

      label {
        color: $primaryP2;
        flex-direction: column;
        width: 100%;
      }
      .stamp_gift_icon {
        background-color: $greyG6;
        border-radius: 100%;
        margin-inline-start: -15px;
        margin-top: -15px;
        z-index: 3;

        img {
          height: 40px;
          width: 40px;
          filter: $primaryP2Filter;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  div.campagin_card {
    .campagin_card_button_area {
      min-height: 64px;
      .campagin_card_button_container {
        display: flex;
        button.campagin_card_button {
          min-width: 240px;
          height: 40px;
          padding: 13px 10px;
        }
      }
      .campaign_card_process_container .stamp_gift_icon > img {
        width: 48px;
        height: 48px;
      }
    }
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  div.campagin_card {
    .campagin_card_button_area {
      .campagin_card_button_container {
        display: flex;
        button.campagin_card_button {
          min-width: 240px;
          height: 40px;
          padding: 13px 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  div.campagin_card {
    .campagin_card_button_area {
      .campagin_card_button_container {
        display: flex;
        button.campagin_card_button {
          padding: 13px 16px;
          min-width: 132px;
          height: 32px;
        }
      }
    }
  }
}

@media (max-width: 474px) {
  div.campagin_card {
    .campagin_card_image_area .campagin-card-tag {
      min-height: 20px;
    }
  }
}
