@import "../frontend_common/Theme.scss";
.text-color-primaryP2 {
  color: $primaryP2;
}
.redeem-gift-card-page {
  min-width: 100vw;
  min-height: 100vh;
  background-color: $primaryP1;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .redeem-gift-card-main-body {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    background-color: $greyG8;
    padding: 16px 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .login-iframe-contanier {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    gap: 40px;
    
    & > iframe {
      width: 100%;
      min-height: 370px;
      transition: min-height 0.3s linear;
      border-radius: 8px;
      border: solid 1px $greyG5;
    }
    .redeem-result-text-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      text-align: left;
      color: $greyG1;
    }

    .text-description {
      text-align: center;
    }
    .text-tips {
      text-align: center;
      color: $greyG3;
    }

    svg.redeem-success {
      > path,
      > rect {
        stroke: $secondaryS2;
      }
    }
    svg.redeem-failed {
      > path,
      > rect {
        stroke: $secondaryS1;
      }
    }
  }
  
}

.text-subheader {
  text-align: center;
  color: $greyG1;
}

.redeem-gift-card-title {
  text-align: left;
  text-transform: uppercase;
  color: $greyG1;
}

.redeem-gift-card-tips,
.redeem-gift-card-instruction {
  text-align: left;
  color: $greyG3;
}

.redeem-gift-card-instruction-section {
  padding: 24px;
  border-radius: 8px;
  background-color: $greyG6;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .redeem-gift-card-instruction {
    padding-inline-start: 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.redeem-gift-card-secondary-title {
  text-align: left;
  color: $greyG1;
}
.redeem-gift-card-modal .modal-body {
  background-color: $greyG8;
  border: none;
  border-radius: 8px;
  padding: 32px 24px;

  text-align: left;

  color: $greyG1;

  .modal-title {
    text-align: left;
    margin-bottom: 8px;
  }

  > ul {
    padding-inline-start: 20px;
    margin-bottom: 40px;
  }

}
.redeem-gift-card-button-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: center;

  text-align: left;

  > button {
    border: none;
    border-radius: 8px;
    background-color: $primaryP2;
    color: $greyG8;
    min-height: 56px;
    width: 100%;
    border-radius: 100px;

    &:focus { 
      outline: 0;
    }
  }
}
@media(min-width: 553px) {
  .redeem-gift-card-page {
    padding: 40px;
    .login-iframe-contanier > iframe {
      width: 375px + 17px;
    }
    .redeem-gift-card-main-body {
      padding: 40px;
    }
  }
}

@media(min-width: 624px) {
  .redeem-gift-card-modal {
    .modal-dialog-centered {
      max-width: 624px;
    }
  }
  .redeem-gift-card-button-group {
    flex-direction: row;
  }
  .redeem-gift-card-button-group > button {
    flex: 1;
    max-width: 50%;
  }
}