@import "./src/frontend_common/Theme.scss";

div.staff_use_container {
  background-color: $greyG8;
  width: 300px;
  margin-left: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 20px 20px 30px;
}
.staff_use_container > div.media {
  margin-bottom: 5px;
}
.staff_use_message {
  margin-bottom: 12px;
  color: $greyG3;
  margin-left: 7px;
}
.use_coupon_btn_group {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .bottom_btn {
    background: $greyG8;
    color: $primaryP2;
    border: 2px solid $primaryP2;
    
    &:hover {
      color: $primaryP2;
    }
  }
}
.staff_use_btn {
  @if $enableCustomBtnStyle ==1 {
    background: $primaryButtonBackground;
    color: $primaryButtonColor;
    &:disabled {
      opacity: 0.6;
    }
  } @else {
    background: $primaryP2;
    color: $greyG8;
    &:disabled {
      background: $greyG4;
    }
  }
  width: 100%;
  border-radius: 100px;
  border: 0;
  text-align: center;
  padding: 12px 35px;
  justify-content: center;
  &:focus,
  &:active {
    outline: 0;
  }
}

.use_coupon_modal {
  > div > div.modal-content {
    background-color: $greyG8;
    padding: 32px 24px;
    > div.modal-header {
      border: 0;
      padding: 13px;
      padding-bottom: 10px;
    }
    > div.modal-body {
      padding: 0;
    }
    .react-code-input > input {
      background-color: $greyG8;
    }
  }
}
.coupon_code_fields,
.react-code-input {
  display: flex !important;
  justify-content: space-between;
  // padding-right: 90px;
  > input {
    width: 49px;
    border: solid 1px $greyG5;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    text-align: center;
    color: $greyG3;
    caret-color: $greyG3;
    &:focus {
      outline: 0px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}
.use_coupon_close_btn {
  margin-left: auto;
  background-color: $greyG8;
  border: 0;
  margin-right: -20px;
  &:focus {
    outline: 0px;
  }
}
.use_coupon_title {
  color: $greyG1;
  margin: 0 0 12px;
  word-wrap: break-word;
}
.use_coupon_message {
  color: $greyG1;
  margin: 0;
  min-height: 72px;
}
.button_group {
  display: flex;
  justify-content: right;
  gap:24px;
}
.use_coupon_status_modal {
  .modal-dialog {
    max-width: 624px;
  }
  .modal-body {
    padding: 32px 24px;
    width: 100%;
    max-height: auto;
  }
}
.use_coupon_modal_content {
  gap: 40px;
}

@media (max-width: 1023px) {
  div.staff_use_container {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 12px 16px;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;

    > div.media > img {
      align-self: center;
    }
  }
  .use_coupon_btn_group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    text-align: center;
    .staff_use_btn {
      order: 2;
    }
    .bottom_btn {
      order: 1;
    }
  }
  .staff_use_message {
    margin-bottom: 0px;
    margin-left: 1px;
    max-width: 129px;
  }
  .staff_use_btn {
    border-radius: 100px;
    padding: 13px 16px;
    width: auto;
  }
  .staff_use_icon {
    width: 40px;
    height: 40px;
  }
  .react-code-input {
    padding-right: 69px;
    justify-content: space-between;
  }
  .react-code-input > input {
    width: calc((100% - 60px)/ 6);
    max-width: 86px;
  }
}
