@import "../../frontend_common/Theme.scss";

.open-app-banner-container {
  position: fixed;
  top: 0px;
  height: var(--app-banner-height);
  width: 100%;
  background-color: $greyG6;
  z-index: 999;
  padding: 0 16px;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  .app-icon {
    width: 50px;
    height: 50px;
  }
  .open-app-banner-content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }
  .open-app-btn {
    padding: 0 16px;
    height: 32px;
    width: auto;

    text-align: center;
    background: $primaryP2;
    color: $greyG8;
  }
}
.open-app-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;

  .app-name {
    text-align: left;
    color: $greyG1;
  }
  .open-app-description {
    text-align: left;
    color: $greyG2;
  }
}
.app-banner-close-btn {
  width: 12px;
  height: 12px;
  cursor: pointer;
  & >path {
    stroke: $greyG1;
  }
}