@import "../../frontend_common/Theme.scss";

@media (max-width: 767px) {
  .footer_container {
    display: grid;
  }

  .footer_right {
    display: grid !important;
  }

  .page_footer {
    padding: 20px 0px 0px 0px !important;
  }

  .copyright,
  .footer_link {
    margin-left: 0px !important;
    line-height: 35px;
  }
}

div.footer_container {
  height: auto;
  background-color: $greyG7;
  max-width: 100%;
  padding: auto;
  margin: auto;
  &.background-greyG8 {
    background-color: $greyG8;
  }
}

.page_footer {
  border-top: solid 1px #d1d1d1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle_btn {
  width: 175px;
  border: solid 1px #d1d1d1;
  background-color: $greyG8;
  justify-content: space-between;
  display: flex;
  align-items: center;
  &:focus {
    outline: 0;
  }
}

.down_arrow_btn {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.dropdown {
  display: inline-flex;
}

.dropdown-item {
  &:hover,
  &:active {
    background-color: #eeeeee;
  }
}

.copyright,
.footer_link {
  color: $primaryP1;
  margin-left: 30px;
  margin-bottom: 0px;
}
.footer_link:hover {
  color: $primaryP2;
}

.footer_left,
.footer_right {
  display: flex;
}
.footer_right {
  justify-content: flex-end;
  display: flex;
}

.language_label,
.language {
  color: #2d2d2d;
  margin-bottom: 0px;
}

.languabe_icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 7px;
}
