@import '../../frontend_common/Theme.scss';

.additional-data-choices-scrollable-contaniner {
  max-height: 336px;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: $greyG4;
    border-radius: 2px;
  }
  > div {
    display: flex;
    color: $greyG3;
    align-items: center;

    >svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }
}

.additoional-data-display-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.additional-field-choice {
  cursor: pointer;
  width: fit-content;
}