// use for en language
@font-face {
    font-family: "Wigrum-Light";
    src: url("../assets/fonts/Wigrum-Light.ttf");
}
@font-face {
    font-family: "Wigrum-Regular";
    src: url("../assets/fonts/Wigrum-Regular.ttf");
}
@font-face {
    font-family: "Wigrum-Medium";
    src: url("../assets/fonts/Wigrum-Medium.ttf");
}
@font-face {
    font-family: "Wigrum-Bold";
    src: url("../assets/fonts/Wigrum-Bold.ttf");
}
@font-face {
    font-family: "Wigrum-Black";
    src: url("../assets/fonts/Wigrum-Black.ttf");
}

// use for sc language
@font-face {
    font-family: "NotoSansSC-Black";
    src: url("../assets/fonts/NotoSansSC-Black.ttf");
}
@font-face {
    font-family: "NotoSansSC-Bold";
    src: url("../assets/fonts/NotoSansSC-Bold.ttf");
}
@font-face {
    font-family: "NotoSansSC-Medium";
    src: url("../assets/fonts/NotoSansSC-Medium.ttf");
}
@font-face {
    font-family: "NotoSansSC-Regular";
    src: url("../assets/fonts/NotoSansSC-Regular.ttf");
}

// use for tc language
@font-face {
    font-family: "NotoSansTC-Black";
    src: url("../assets/fonts/NotoSansTC-Black.ttf");
}
@font-face {
    font-family: "NotoSansTC-Bold";
    src: url("../assets/fonts/NotoSansTC-Bold.ttf");
}
@font-face {
    font-family: "NotoSansTC-Medium";
    src: url("../assets/fonts/NotoSansTC-Medium.ttf");
}
@font-face {
    font-family: "NotoSansTC-Regular";
    src: url("../assets/fonts/NotoSansTC-Regular.ttf");
}

.title-x-large {
    font-family: "Wigrum-Bold";
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        line-height: 46px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        line-height: 46px;
    }
}

.title-large {
    font-family: "Wigrum-Bold";
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        line-height: 42px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        line-height: 42px;
    }
}

.title-medium-1 {
    font-family: "Wigrum-Medium";
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Medium";
        font-weight: 500;
        line-height: 36px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Medium";
        font-weight: 500;
        line-height: 36px;
    }
}

.title-medium-2 {
    font-family: "Wigrum-Black";
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        font-weight: 700;
        line-height: 36px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        font-weight: 700;
        line-height: 36px;
    }
}

.title-small {
    font-family: "Wigrum-Black";
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        font-weight: 700;
        line-height: 28px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        font-weight: 700;
        line-height: 28px;
    }
}

.headline {
    font-family: "Wigrum-Bold";
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        line-height: 26px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        line-height: 26px;
    }
}

.subheading {
    font-family: "Wigrum-Medium";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        font-weight: 700;
        line-height: 24px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        font-weight: 700;
        line-height: 24px;
    }
}

.field-text {
    font-family: "Wigrum-Light";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Regular";
        line-height: 24px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Regular";
        line-height: 24px;
    }
}

.body-1 {
    font-family: "Wigrum-Light";
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Medium";
        font-weight: 400;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Medium";
        font-weight: 400;
        line-height: 22px;
    }
}

.body-2 {
    font-family: "Wigrum-Regular";
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        font-weight: 700;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        font-weight: 700;
        line-height: 22px;
    }
}

.body-3 {
    font-family: "Wigrum-Bold";
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Black";
        font-weight: 900;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Black";
        font-weight: 900;
        line-height: 22px;
    }
}

.button-large {
    font-family: "Wigrum-Medium";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Medium";
        font-weight: 500;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Medium";
        font-weight: 500;
        line-height: 22px;
    }
}

.button-small {
    font-family: "Wigrum-Medium";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Medium";
        font-weight: 500;
        line-height: 20px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Medium";
        font-weight: 500;
        line-height: 20px;
    }
}

.tag-menu-1 {
    font-family: "Wigrum-Light";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Regular";
        line-height: 16px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Regular";
        line-height: 16px;
    }
}

.tag-menu-2 {
    font-family: "Wigrum-Medium";
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Bold";
        font-weight: 700;
        line-height: 16px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Bold";
        font-weight: 700;
        line-height: 16px;
    }
}

.caption {
    font-family: "Wigrum-Regular";
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "NotoSansSC-Medium";
        line-height: 14px;
    }
    &:lang(zh-Hant) {
        font-family: "NotoSansTC-Medium";
        line-height: 14px;
    }
}
