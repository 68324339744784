
@import './src/frontend_common/Theme.scss';

.transaction_list_transactionItem {
    // height: 133px;
    width: 100%;
    padding: 24px;
    display: flex;
    cursor: pointer;
    &:hover {
        background-color: $greyG6;
    }
    &.active {
        background-color: $greyG6;
    }
    .transactionItem_active {
        height: 100%;
        width: 1%;
        background-color: $primaryP2;
    }
    .transactionItem_container {
        position: relative;
        flex: 1;
        height: 100%;
        width: 92%;
        .transactionItem_title_area {
            // margin-top: 19px;
            display: flex;
            .transactionItem_title_area_left {
                margin-right: 12px;
                .transactionItem_icon {
                    width: 56px;
                    height: 56px;
                    border-radius: 100px;
                    border: 1px solid $greyG5;
                }
            }
            .transactionItem_title_area_right {
                width: 100%;

                .transactionItem_title_area_right_top {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 8px 0;
                }
                .transactionItem_creation_date {
                    flex: 1 0 0;
                    color: $greyG3;
                }
                .transactionItem_status {
                    display: flex;
                    padding: 2px 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    color: $greyG8;
                    text-align: center;

                    background-color: $secondaryS1;
                }
                .transactionItem_status.approved{
                    background-color: $secondaryS2;
                }
                .transactionItem_status.rejected{
                    background-color: $secondaryS4;
                }
                .transactionItem_status.recalled {
                    background-color: $secondaryS3;
                }
                .transactionItem_title {
                    align-self: stretch;
                    color: $greyG1;

                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                }
            }
        }

        .transactionItem_line {
            margin: 16px 0;
            border: none;
            border-top: 1px dashed $greyG5;

            // border-bottom: 1px solid $greyG5;
            background: $greyG8;
            // height: 0px;
            // border: 1px solid $greyG5;
            // align-self: stretch;
            // margin: 16px 0;

            // stroke-width: 1px;
            // stroke: $greyG5;
        }

        .transactionItem_content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;     

            .transactionItem_content_item {
                color: $greyG3;

                span {
                    padding-left: 4px;
                    color: $greyG2;
                }
            }
        }
    }
}
