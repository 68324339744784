@import './src/frontend_common/Theme.scss';

.login-method-modal {
  .login-method-modal-body {
    padding: 32px 24px;
    width: 100%;
    height: auto;
    background-color: $greyG8;
    border-radius: 8px;
    .home-profile-custom-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .close-icon,
      .close-icon > img {
        width: 48px;
        height: 48px;
        cursor: pointer;
      }
      .close-icon {
        background-color: $opacityOP1;
        border-radius: 24px;
        > img {
          filter: $greyG8Filter;
        }
      }
    }
    .home-profile-custom-verify-code {
      width: 100%;
      height: 36px;

      border: 0px;
      border-bottom: 1px solid $greyG5;
      background-color: $greyG8;

      &.has-error {
        border-bottom: 1px solid $secondaryS4;
      }

      input {
        flex: 1;
        min-width: 50px;
        height: 35px;
        padding: 0;
        background-color: $greyG8;
        border: 0;
        color: $primaryP2;
        &::placeholder {
          color: $greyG4;
          opacity: 1;
          /* Firefox */
        }
        &:focus {
          outline: 0;
        }
        &:focus-visible {
          outline: 0;
        }
      }
    }
    .home-profile-mobile {
      height: auto;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;

      .home-profile-custom-mobile.country-code {
        width: 72px;
        flex: 0;
        .country-code-picker-box {
          width: 72px;
          .account-input-with-downarrow {
            width: 72px;
          }
          .account-down-arrow {
            margin-top: 10px;
          }
        }
      }
    }
    .bottom-line {
      height: 36px;
      border: 0px;
      border-bottom: 1px solid $greyG5;
      background-color: $greyG8;
    }
    .has-error {
      .bottom-line {
        border-bottom: 1px solid $secondaryS4;
      }
    }
    .home-profile-custom-mobile,
    .home-profile-custom-email {
      input {
        height: 35px;
        padding: 0;
        background-color: $greyG8;
        border: 0;
        color: $primaryP2;
        &::placeholder {
          color: $greyG4;
          opacity: 1;
          /* Firefox */
        }
        &:focus {
          outline: 0;
        }
        &:focus-visible {
          outline: 0;
        }
      }
      .button-small {
        margin: 0;
        height: 32px;
        width: auto;
        padding: 0 16px;
      }
    }
    .home-profile-custom-mobile {
      width: auto;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .mobile-input {
        flex: 1;
      }
    }
    .home-profile-custom-email {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .email-input {
        flex: 1;
      }
    }
    .account-error {
      height: 30px;
    }
    .login-btn {
      align-self: flex-start;
      margin-bottom: 20px;

      width: auto;
      height: 32px;
      padding: 0 16px;

      background-color: $greyG8;
      border: solid 2px $primaryP2;
      border-radius: 25px;

      color: $primaryP2;
      line-height: 30px;
      text-align: center;
      &:focus {
        outline: 0;
      }
    }
  }
}

.direct-marketing-section-content {
  margin-top: 8px;
}

.direct-marketing-section-button {
  margin-top: 16px;
  width: auto;

  &.direct-marketing-section-agree-button {
  }
  
  &.direct-marketing-section-disagree-button {
    border: solid 2px $primaryP2;
    color: $primaryP2;
    background: $greyG8;
  }
}
